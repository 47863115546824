import React from "react"
import styled, { keyframes } from "styled-components"
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Layout from "../components/layout"
import audi from "../images/audi.gif"

const Title = styled.div`
  color: rgb(197, 158, 93);
  font-size: 2em;
  font-weight: 600;
  margin: 10vh auto;
  @media (max-width: 600px) {
    font-size: 1em;
    margin: 10px 0px;
  } ;
`

const ListMobile = styled.ul`
  list-style: none;
  margin-left: 0px;
  padding-bottom: 20vh;
  @media (max-width: 1200px) {
    display: block;
  }
  @media (min-width: 1201px) {
    display: none;
  }
`

const ListDesktop = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
  @media (min-width: 1201px) {
    display: block;
    list-style: none;
  }
`

const ListItem = styled.li`
  /* cursor: pointer; */
  color: rgb(197,158,93);
  font-size: 1em;
  font-weight: 400;
  /* text-shadow: 2px 2px 0px #373485; */
  &:nth-child(2) {
    display: none;
  }
  &:hover {
    &:nth-child(2) {
      display: inline;
      animation: ${fadeIn} 0.8s forwards ease-in;
    }
  }
  @media (max-width: 600px) {
    font-size: 0.5em;
  } ;
`
const Container = styled.div`
  animation: ${fadeIn} 1.2s forwards ease-in;
  height: auto;
  width: 100%;
  margin: auto auto auto 50px;
`

const BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -10000;
  background-image: url(${audi});
  background-size: cover;
  opacity: 0.8;
  animation: ${fadeIn} 1.4s forwards ease-in;
`

function fadeIn() {
  return keyframes`
  0% { opacity:0;};
  50% { opacity:0.5};
  100% { opacity:1;};
`
}

const SecondPage = () => (
  <Layout>
    <Container>
      <Title>Oferujemy:</Title>
      <ListMobile>
        <ListItem>Naprawy powypadkowe wszystkie marki</ListItem>
        <ListItem>
          Bezgotówkowe Rozliczenie z firmami ubezpieczeniowymi
        </ListItem>
        <ListItem>Naprawy mechaniczne</ListItem>
        <ListItem>Wymiana opon</ListItem>
        <ListItem>Wulkanizacja</ListItem>
        <ListItem>Obsługa flot samochodowych</ListItem>
        <ListItem>Całodobowa pomoc drogowa</ListItem>
        <ListItem>Serwis klimatyzacji</ListItem>
        <ListItem>Samochody zastępcze</ListItem>
      </ListMobile>
      <ListDesktop>
        <ListItem>
          Naprawy powypadkowe wszystkie marki
        </ListItem>
        <ListItem>
          Bezgotówkowe Rozliczenie z firmami ubezpieczeniowymi
        </ListItem>
        <ListItem>Naprawy mechaniczne</ListItem>
        <ListItem>Wymiana opon</ListItem>
        <ListItem>Wulkanizacja</ListItem>
        <ListItem>Obsługa flot samochodowych</ListItem>
        <ListItem>Całodobowa pomoc drogowa</ListItem>
        <ListItem>Serwis klimatyzacji</ListItem>
        <ListItem>Samochody zastępcze</ListItem>
      </ListDesktop>
    </Container>
    {/* <BackGround></BackGround> */}
  </Layout>
)

export default SecondPage
